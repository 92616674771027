import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Modal, Box, Typography, Button } from '@mui/material';
import Confetti from 'react-confetti'; // Import the Confetti component
import PasswordPage from './components/PasswordPage';
import FlowerAnimationPage from './components/FlowerAnimationPage';
import PhotoGalleryPage from './components/PhotoGalleryPage';
import Navbar from './components/Navbar';
import { useCallback } from 'react';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handlePasswordSubmit = () => {
    setAuthenticated(true);
    setOpen(true); // Open the birthday popup
  };

  // Memoize handleClose
  const handleClose = useCallback(() => {
    setOpen(false);
    navigate("/flower"); // Navigate to the flower page after closing
  }, [navigate]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [open, handleClose]);

  const popupStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400, // Increased width
    height: 250, // Increased height
    bgcolor: 'background.paper',
    borderRadius: '15px',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    zIndex: 1300, // Ensure popup is on top
    display: 'flex',
    justifyContent: 'center', // Horizontal centering for Flexbox
    alignItems: 'center', // Vertical centering for Flexbox
    flexDirection: 'column', // Ensure content stacks vertically
  };
  

  const textStyle = {
    color: '#ff1493',
    fontFamily: "'Pacifico', cursive",
  };

  return (
    <div className="App">
      {!authenticated ? (
        <PasswordPage onPasswordSubmit={handlePasswordSubmit} />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/flower" element={<FlowerAnimationPage />} />
            <Route path="/gallery" element={<PhotoGalleryPage />} />
          </Routes>
        </>
      )}

      {/* Confetti outside the modal */}
      {open && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ position: 'fixed', top: 0, left: 0, zIndex: 1400 }} // Ensure confetti stays above the backdrop but below the modal
        />
      )}

      {/* Happy Birthday Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={popupStyle}>
          <Typography variant="h4" component="h2" gutterBottom sx={textStyle}>
            Happy Birthday Angie!!!
          </Typography>
          <Typography variant="body1" sx={textStyle}>
            I love you forever~ 🎂🎉🎈
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
