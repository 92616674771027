import React, { useState } from 'react';
import '../styles/PhotoGalleryPage.css';

import img1 from '../assets/photo1.jpeg';
import img2 from '../assets/photo2.jpeg';
import img4 from '../assets/photo4.jpeg';
import img5 from '../assets/photo5.jpeg';
import img6 from '../assets/photo6.jpeg';
import img7 from '../assets/photo7.jpeg';
import img8 from '../assets/photo8.jpeg';
import img9 from '../assets/photo9.jpeg';
import img10 from '../assets/photo10.jpeg';
import img11 from '../assets/photo11.jpeg';
import img12 from '../assets/photo12.jpeg';
import img13 from '../assets/photo13.jpeg';
import img15 from '../assets/photo15.jpeg';
import img16 from '../assets/photo16.jpeg';
import img17 from '../assets/photo17.jpeg';
import img18 from '../assets/photo18.jpeg';
import img19 from '../assets/photo19.jpeg';
import img20 from '../assets/photo20.jpeg';
import img21 from '../assets/photo21.jpeg';
import img22 from '../assets/photo22.jpeg';
import img23 from '../assets/photo23.jpeg';
import img24 from '../assets/photo24.jpeg';

const memories = [
  { src: img1, caption: 'tennis tourney!' },
  { src: img2, caption: 'barnes and nobles!' },
  { src: img4, caption: 'ESTRABERRIIIIII' },
  { src: img5, caption: 'midsummers!' },
  { src: img6, caption: 'tatte on digi!' },
  { src: img7, caption: 'le charcuterie~' },
  { src: img8, caption: 'first formal!' },
  { src: img9, caption: 'finals grind!' },
  { src: img10, caption: 'first facetime!' },
  { src: img11, caption: 'downtown leesburg!' },
  { src: img12, caption: 'cutie!' },
  { src: img13, caption: 'mine all mine <3!' },
  { src: img15, caption: 'obligatory beijing station!' },
  { src: img16, caption: 'POTBELLYYYYYYYY' },
  { src: img17, caption: 'happy valentine\'s day my love!' },
  { src: img18, caption: 'academic wizard of a girl!' },
  { src: img19, caption: 'fitzroy!' },
  { src: img20, caption: 'lake formal!' },
  { src: img21, caption: 'UNOOOOOOOOOOOOO' },
  { src: img22, caption: 'two hours :3' },
  { src: img23, caption: 'skate skate!' },
  { src: img24, caption: 'sweet treat~~' }
];

const PhotoGalleryPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  const handlePrev = () => {
    if (isAnimating) return;
    setDirection('left');
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + memories.length) % memories.length);
      setIsAnimating(false);
    }, 500);
  };

  const handleNext = () => {
    if (isAnimating) return;
    setDirection('right');
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % memories.length);
      setIsAnimating(false);
    }, 500);
  };

  return (
    <div className="photo-gallery">
      {/* Title with Pacifico font */}
      <h1 className="gallery-title">favorite memories of you</h1>
      <div className={`photo-container ${isAnimating ? `sliding-${direction}` : ''}`}>
        <img
          src={memories[currentIndex].src}
          alt={`Memory ${currentIndex + 1}`}
          className="photo"
        />
      </div>
      <div className="caption">
        {memories[currentIndex].caption}
      </div>
      <button className="arrow left" onClick={handlePrev}>‹</button>
      <button className="arrow right" onClick={handleNext}>›</button>
    </div>
  );
};

export default PhotoGalleryPage;
