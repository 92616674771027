import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import '../styles/PasswordPage.css';

const PasswordPage = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'greenberry') {
      onPasswordSubmit();
    } else {
      alert('Hint: our first date location');
    }
  };

  return (
    <div className="password-page">
      <h1>what's the secret password???</h1>
      <form onSubmit={handleSubmit}>
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          gap={2}
        >
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            variant="outlined"
          />
          <Button 
            type="submit" 
            variant="contained" 
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default PasswordPage;
