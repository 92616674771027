import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Navbar.css'; // Import the CSS file

import flowerIcon from './flower-icon.png';
import galleryIcon from './gallery-icon.png';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="navbar-item" onClick={() => navigate('/flower')}>
        <img src={flowerIcon} alt="Flower" />
      </div>
      <div className="navbar-item" onClick={() => navigate('/gallery')}>
        <img src={galleryIcon} alt="Gallery" />
      </div>
    </div>
  );
};

export default Navbar;