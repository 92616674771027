import React, { useEffect } from 'react';
import '../styles/FlowerAnimationPage.css';

const FlowerAnimationPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/flower_animation/flower.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="flower-animation-container">
      {/* title background should be #ffc8f3 */}
      <h1 className="flower-title" >Here's a flower for you!</h1>
      <iframe 
        src="/flower_animation/flower.html" 
        className="flower-animation-frame" 
        title="Flower animation">
      </iframe>
    </div>
  );  
};

export default FlowerAnimationPage;
